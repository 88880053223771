<template>
  <div id="app" :style="cssVars">
    <div
      class="cr"
      :style="containerHeight"
    >
      <transition name="fade">
        <div v-if="isToastShow" class="toast cr__toast">
          <img class="toast__icon" src="@/assets/img/warning.svg" alt="">
          <span class="toast__text">{{ toastMsg }}</span>
        </div>
      </transition>

      <header class="cr__header">
        <img
          :src="$requireSafe(`${siteId}/logo.svg`)"
          alt="logo"
        >
      </header>

      <div class="cr__view">
        <div class="cr__bg">
          <!-- <img
            v-if="isMobile"
            :src="$requireSafe(`${siteId}/bg.jpg`)"
            class="cr__bg--m"
            alt="bg"
          >
          <img
            v-else-if="!isMobile"
            :src="$requireSafe(`${siteId}/bg-sm.png`)"
            alt="bg-sm"
          > -->
          <img
            :src="$requireSafe(`${siteId}/bg-sm.png`)"
            alt="bg-sm"
          >
        </div>
        <div class="cr__banner">
          <img
            :src="$requireSafe(`${siteId}/banner.png`)"
            alt="banner"
          >
        </div>
      </div>

      <nav class="cr__nav">
        <footer class="cr__prefix">
          <div class="cr__preview">
            <img
              :src="$requireSafe(`${siteId}/preview.png`)"
              alt="preview"
            >
          </div>

          <p class="cr__desc fz-sm">
            {{ $t('copyright', { renderHostName }) }}
          </p>
        </footer>

        <ul class="cr__list">
          <li
            class="cr__item"
            :class="[isShowTooltip ? `cr__item--active` : '']"
            @click="toggleTooltip(!isShowTooltip)"
            @mouseenter="isMobile ? null : toggleTooltip(true)"
            @mouseleave="isMobile ? null : toggleTooltip(false)"
          >
            <div class="cr__link">
              <div class="cr__wrap">
                <img
                  class="cr__icon"
                  :src="$requireSafe(`${siteId}/home.svg`)"
                  alt="ENTER INDEX"
                >
                <img
                  class="cr__icon cr__icon--active"
                  :src="require('@/assets/img/home-active.svg')"
                  alt="ENTER INDEX"
                >
              </div>

              <div class="cr__label">
                <h2 class="cr__title fz-md">
                  {{ $t('homePage') }}
                </h2>
                <h3 class="cr__subtitle fz-sm">
                  ENTER INDEX
                </h3>
              </div>
            </div>

            <div
              v-show="isShowTooltip"
              class="cr__tooltip"
            >
              <div
                v-for="(item, idx) in urlList"
                :key="`pingArr[${idx}]`"
                class="cr__row"
              >
                <div class="cr__square cr__ping fz-sm">
                  {{ item.ping }}ms
                </div>

                <img
                  class="cr__arrow"
                  :src="require('@/assets/img/arrow.svg')"
                  alt=""
                >

                <div class="cr__square cr__url fz-sm">
                  {{ item.url }}
                </div>

                <a
                  href="javascript:void(0)"
                  class="cr__square cr__enter fz-sm"
                  :class="{ 'cr__enter--disabled': item.disabled }"
                  @click="goWebsite(item.url, item.disabled)"
                >
                  {{ item.disabled ? $t('close') : $t('enter') }}
                </a>
              </div>
            </div>
          </li>

          <li class="cr__item">
            <a
              :href="appUrl"
              class="cr__link"
            >
              <div class="cr__wrap">
                <img
                  class="cr__icon"
                  :src="$requireSafe(`${siteId}/download.svg`)"
                  alt="APP DOWNLOAD"
                >
                <img
                  class="cr__icon cr__icon--active"
                  :src="require('@/assets/img/download-active.svg')"
                  alt="APP DOWNLOAD"
                >
              </div>

              <div class="cr__label">
                <h2 class="cr__title fz-md">
                  {{ $t('download') }}
                </h2>
                <h3 class="cr__subtitle fz-sm">
                  APP DOWNLOAD
                </h3>
              </div>
            </a>
          </li>

          <li class="cr__item">
            <a
              :href="sharedUrl"
              class="cr__link"
            >
              <div class="cr__wrap">
                <img
                  class="cr__icon"
                  :src="$requireSafe(`${siteId}/register.svg`)"
                  alt="Sign up now"
                >
                <img
                  class="cr__icon cr__icon--active"
                  :src="require('@/assets/img/register-active.svg')"
                  alt="Sign up now"
                >
              </div>

              <div class="cr__label">
                <h2 class="cr__title fz-md">
                  {{ $t('register') }}
                </h2>
                <h3 class="cr__subtitle fz-sm">
                  Sign up now
                </h3>
              </div>
            </a>
          </li>

          <li class="cr__item">
            <a
              :href="serviceUrl"
              class="cr__link"
            >
              <div class="cr__wrap">
                <img
                  class="cr__icon"
                  :src="$requireSafe(`${siteId}/service.svg`)"
                  alt="ONLINE SERVICE"
                >
                <img
                  class="cr__icon cr__icon--active"
                  :src="require('@/assets/img/service-active.svg')"
                  alt="ONLINE SERVICE"
                >
              </div>

              <div class="cr__label">
                <h2 class="cr__title fz-md">
                  {{ $t('service') }}
                </h2>
                <h3 class="cr__subtitle fz-sm">
                  ONLINE SERVICE
                </h3>
              </div>
            </a>
          </li>
        </ul>
      </nav>

      <footer class="cr__footer cr__footer--mobile">
        <p class="cr__desc fz-sm">
          {{ $t('copyright', { renderHostName }) }}
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import SiteApi from '@/assets/js/api/siteApi.js';

const maxCount = 10;

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      isShowTooltip: false,
      urlList: [],
      appUrl: '',
      serviceUrl: '',
      locale: '',
      siteId: '',
      sharedUrl: '',
      indexList: [],
      isMobile: false,
      toastMsg: '',
      isToastShow: false,
    };
  },
  computed: {
    containerHeight() {
      const vm = this;
      if (!vm.isMobile || !vm.isShowTooltip) return {};
      return {
        minHeight: '700px',
      };
    },
    renderHostName() {
      const list = {
        201: 'AEGAME',
        402: '皇冠集团',
        803: '金宝博',
      };
      return list[this.siteId] ?? '';
    },
    cssVars() {
      const { siteId } = this;
      const primaryColor = {
        201: '#e9ad34',
        402: '#866638',
        803: '#e9ad34',
      };

      return {
        '--primary-color': primaryColor[siteId] || '#866638',
      };
    },
  },
  watch: {
    siteId() {
      const vm = this;
      vm.setTitle();
    },
  },
  async created() {
    const vm = this;
    vm.setUrlList();
    await vm.getPingArr();
    await vm.getSiteInfo();
  },
  mounted() {
    const vm = this;
    vm.handleResize();
    window.addEventListener('resize', vm.handleResize);
  },
  beforeDestroy() {
    const vm = this;
    window.removeEventListener('resize', vm.handleResize);
  },
  methods: {
    setTitle() {
      const vm = this;
      const list = {
        201: 'AEGAME.com',
        402: '6789.app',
        803: '188c.com',
      };
      document.title = list[vm.siteId] ?? '';
      const favicon = document.getElementById('favicon');
      favicon.href = `site/${vm.siteId}/favicon.ico`;
    },
    showToast(msg, duration = 3000) {
      const vm = this;
      vm.toastMsg = msg;
      vm.isToastShow = true;
      setTimeout(() => {
        vm.isToastShow = false;
        vm.toastMsg = '';
      }, duration);
    },
    setUrlList() {
      const vm = this;
      const { siteDomainList } = window._jsvar;
      vm.urlList = siteDomainList.map((item) => ({
        url: item,
        ping: '10',
        disabled: false,
      }));
    },
    checkDevice() {
      const vm = this;
      vm.isMobile = window.matchMedia('(max-width: 768px)').matches;
    },
    handleFullHeight() {
      const vm = this;
      const vh = window.innerHeight * 0.01;
      document.querySelector('.cr').style.setProperty('--vh', `${vh}px`);
      vm.fullVh = vh * 100;
    },
    handleResize() {
      const vm = this;
      vm.handleFullHeight();
      vm.checkDevice();
    },
    getPingArr() {
      const vm = this;

      const storagePingArr = [];
      vm.urlList.forEach(() => {
        storagePingArr.push([]);
      });
      vm.urlList.forEach((item, index) => {
        const loadTimer = setInterval(async () => {
          const { time, disabled } = await vm.getLoadTime(`${item.url}/favicon.ico`);
          storagePingArr[index].push(time);
          vm.$set(item, 'ping', time);
          vm.$set(item, 'disabled', disabled);
          vm.indexList.push(index);
          if (storagePingArr[index].length === maxCount) {
            for (let i = 0; i < 2; i++) {
              const maxPing = Math.max.apply(null, storagePingArr[index]);
              storagePingArr[index].splice(storagePingArr[index].indexOf(maxPing), 1);
            }
            const finalPing = vm.getArrayAvg(storagePingArr[index]);
            item.ping = Math.round(finalPing);

            clearInterval(loadTimer);
            if (index === vm.indexList[vm.indexList.length - 1] && vm.indexList.length === maxCount * vm.urlList.length) {
              vm.urlSort();
            }
          }
        }, 1000);
      });
    },
    getLoadTime() {
      return new Promise(((resolve) => {
        // const img = new Image();
        const timeStart = new Date();
        // img.src = url;
        // img.onerror = function run() {
        //   const timeEnd = new Date();
        //   resolve({ time: timeEnd.getTime() - timeStart.getTime(), disabled: true });
        // };
        // img.onload = function run() {
        const timeEnd = new Date();
        resolve({ time: timeEnd.getTime() - timeStart.getTime(), disabled: false });
        // };
      }));
    },
    urlSort() {
      const vm = this;
      vm.urlList.sort((a, b) => {
        if (a.disabled === b.disabled) {
          return a.ping - b.ping;
        }

        return a.disabled - b.disabled;
      });
    },
    getArrayAvg(array) {
      const len = array.length;
      let sum = 0;
      for (let i = 0; i < len; i++) {
        sum += array[i];
      }
      return sum / len;
    },
    async getSiteInfo() {
      const vm = this;
      const urlList = vm.urlList.filter((e) => !e.disabled);
      for (let i = 0; i < vm.urlList.length; i++) {
        SiteApi.SERVER = `${urlList[i].url}/api`;
        // 需求是 call api 不如預期才進下一個迭代，故 disable eslint 的限制
        // eslint-disable-next-line
        const { code, data } = await SiteApi.getSiteInfo();

        if (code === 200) {
          vm.appUrl = data.appUrl;
          vm.locale = data.locale;
          vm.serviceUrl = data.serviceUrl[vm.locale?.toLowerCase()] || data.serviceUrl.default;
          vm.siteId = data.siteId;
          vm.sharedUrl = `${urlList[i].url}?a=x&c=`;
          vm.$i18n.locale = vm.locale;

          break;
        }

        if (i === vm.urlList.length - 1 && code !== 200) {
          vm.showToast('Try again later');
        }
      }
    },
    toggleTooltip(isShow) {
      const vm = this;
      vm.isShowTooltip = isShow;
    },
    goWebsite(item, disabled) {
      if (disabled) return;

      window.location.href = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.cr {
  $this: &;

  @include flex-col;

  position: relative;
  justify-content: space-between;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  &__header {
    position: absolute;
    z-index: 2;
    display: none;
    width: 100%;
    min-height: 70px;
    padding: 7.5px 0;
    background: #fff;
    box-shadow: 0 3px 6px #00000029;

    @include mediaquery_minipad {
      @include flex-center;
    }

    @include mediaquery_phone {
      min-height: 40px;
    }
  }

  &__header img {
    width: 30%;
  }

  &__view {
    position: relative;
    flex: 1 0 0;
    width: 100%;

    @include mediaquery_minipad {
      padding-top: 70px;
    }

    @include mediaquery_phone {
      padding-top: 40px;
    }
  }

  &__bg {
    height: 100%;

    &--m {
      position: absolute;
      top: 50px;
    }
  }

  &__bg > img {
    min-height: 100%;
    object-fit: cover;
  }

  &__banner {
    @include abs-center-y;

    right: 80px;
    width: 26%;

    @include mediaquery_minipad {
      display: none;
    }
  }

  &__nav {
    display: flex;
  }

  &__prefix {
    position: relative;
    width: 27%;
    border: 1px solid #4d5772;
    background: #4d5772;

    @include mediaquery_minipad {
      display: none;
    }
  }

  &__preview {
    @include abs-center-x;

    bottom: 115px;
    width: 61%;
  }

  &__desc {
    padding: 95px 0 61px;
    color: #b8bfd2;
    line-height: 1.5;
    text-align: center;
  }

  &__list {
    display: flex;
    width: 73%;

    @include mediaquery_minipad {
      position: relative;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
  }

  &__item {
    @include flex-center;

    position: relative;
    flex: 1 0 0;
    border: 1px solid #b8bfd2;
    background: #fff;
    cursor: pointer;

    @include mediaquery_minipad {
      position: static;
      display: block;
      flex: 50% 0 0; // 扣掉兩個 item 的 左右 border 共 4px
      max-width: 50%;
    }

    &:hover,
    &--active {
      color: #fff;
      background: var(--primary-color);

      #{$this}__title,
      #{$this}__subtitle {
        color: #fff;
      }

      #{$this}__icon--active {
        opacity: 1;
      }
    }
  }

  &__link {
    @include flex-center;

    width: 100%;
    height: 100%;

    @include mediaquery_minipad {
      flex-direction: column;
      padding: 21px 0;
    }
  }

  &__wrap {
    position: relative;
    width: 64px;
    margin-right: 20px;

    @include mediaquery_pc {
      margin-right: 10px;
    }

    @include mediaquery_minipad {
      width: 32px;
      margin: 0 0 10px;
    }
  }

  &__icon {
    width: 100%;

    &--active {
      position: absolute;
      top: 0;
      opacity: 0;
    }
  }

  &__title {
    font-weight: 600;

    @include mediaquery_minipad {
      text-align: center;
    }
  }

  &__subtitle {
    margin-top: 10px;
    color: #a1a1a1;
  }

  &__tooltip {
    @include abs-center-x;

    bottom: 167px;
    z-index: 5;
    padding: 20px;
    border: 2px solid #b5975b;
    border-radius: 5px;
    background: #fff;

    @include mediaquery_minipad {
      right: 0;
      bottom: 235px;
      left: 0;
      transform: none;
    }

    &::before,
    &::after {
      @include abs-center-x;

      width: 0;
      height: 0;
      content: '';

      @include mediaquery_minipad {
        left: 25%;
      }
    }

    &::before {
      bottom: -10px;
      border-top: 10px solid #b5975b;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &::after {
      bottom: -7px;
      border-top: 7px solid #fff;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }
  }

  &__row {
    @include flex-expand;

    & + & {
      margin-top: 15px;

      @include mediaquery_phone {
        margin-top: 10px;
      }
    }
  }

  &__square {
    padding: 8px 0;
    border: 1px solid #b8bfd2;
    border-radius: 2px;
    color: #414755;
    background: #ecf2f8;

    @include mediaquery_phone {
      padding: 6px 0;
    }
  }

  &__ping {
    min-width: 80px;
    text-align: center;

    @include mediaquery_phone {
      min-width: 56px;
    }
  }

  &__url {
    flex: 1 0 0;
    min-width: 240px;
    padding: 8px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mediaquery_phone {
      min-width: 120px;
      padding: 6px 10px;
    }
  }

  &__arrow {
    width: 26px;
    margin: 0 12px;

    @include mediaquery_minipad {
      margin: 0 5px;
    }
  }

  &__enter {
    width: 120px;
    margin-left: 20px;
    border-color: #4d5772;
    color: #fff;
    text-align: center;
    background: #4d5772;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @include mediaquery_minipad {
      margin-left: 5px;
    }

    @include mediaquery_phone {
      width: 74px;
    }
  }

  &__footer--mobile {
    display: none;
    border: 1px solid #4d5772;
    background: #4d5772;

    @include mediaquery_minipad {
      display: block;
    }

    .cr__desc {
      padding: 20px 0;
    }
  }
}

.toast {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 1000;
  display: flex;
  align-items: center;
  min-width: 80px;
  padding: 10px 15px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 4px 12px #00000026;
  transform: translate3d(-50%, 0, 0);

  &__icon {
    width: 16px;
  }

  &__text {
    margin-left: 5px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
