import Api from '@/assets/js/api/api';

class SiteApi extends Api {
  static async getSiteInfo() {
    const result = await this.callAxios('GET', '/system/site-info/get');
    return result;
  }
}

export default SiteApi;
