import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhCN from './lang/zh_CN';
import viVN from './lang/vi_VN';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'zh_CN',
  messages: {
    zh_CN: zhCN,
    vi_VN: viVN,

  },
});
export default i18n;
